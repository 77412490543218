/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css?family=Open+Sans:700|Roboto+Condensed:700|Roboto+Slab|Roboto:900');

/*
 font-family: 'Open Sans', sans-serif;
 font-family: 'Roboto', sans-serif;
 font-family: 'Roboto Condensed', sans-serif;
 font-family: 'Roboto Slab', serif;
*/

h1 h2 h3 h4 h5 h6 {
  font-family: 'Open Sans', sans-serif;
}

html {
  height: 100%;
  box-sizing: border-box;
  overflow-y: scroll; /* All browsers without overlaying scrollbars */
}

*,
::before,
::after {
  background-repeat: no-repeat; /* Set `background-repeat: no-repeat` to all elements and pseudo elements */
  box-sizing: inherit;
}

::before,
::after {
  text-decoration: inherit; /* Inherit text-decoration and vertical align to ::before and ::after pseudo elements */
  vertical-align: inherit;
}

* {
  padding: 0; /* Reset `padding` and `margin` of all elements */
  margin: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Roboto condensed', sans-serif;
  min-height: 100%;
  background: #220419;
  /* background: url(/assets/images/VerbodenStad.jpg) no-repeat center center fixed; */
  /* background-size: cover; */
}

:root {
  --site-bg-color: rgba(255, 255, 255, 0.45);
  --site-fg-color: #4a4a4a;
  --site-strong-color: #404040;
  --site-strongfg-color: #fafafa;
  --site-link-color: #0000ee;
}

router-outlet {
  display: none;
  visibility: hidden;
  position: absolute;
}

div.subPage {
  padding: 25px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  height: 100%;
  align-items: center;

  & h3,
  & h2 {
    /* margin-bottom: 15px; */
    text-align: center;
    display: none;
  }

  & p {
    font-size: 1.2em;
  }

  & div.cols {
    display: grid;
    height: 100%;
    padding-top: 0.5rem;
    grid-template-columns: 70vw 20vw;
    grid-template-rows: 1fr;
    gap: 1rem;
    align-items: stretch;
    align-content: stretch;

    & se-info-column {
      & a {
        display: block;
        margin-bottom: 5px;
        border-bottom: 1px solid var(--site-strong-color);
        text-align: right;
      }
    }
  }
}

.pane {
  display: flex;
  align-items: stretch;
  /* // justify-content: center; */
  padding: 5px 10px;
  flex-direction: row;
  width: 100%;
  min-height: 100%;
}

.pane > * {
  flex: 0 0 100%;
}
p {
  margin-bottom: 6px;
}

.panel {
  display: flex;
  padding: 0;
  margin: 0;
  flex-direction: column;
  background-color: var(--site-bg-color);
  border-top-right-radius: 14px;
  border-bottom-left-radius: 14px;
  margin-bottom: 7px;

  & > main {
    margin: 5px 10px;
  }

  & dd > dl {
    margin-top: 10px;
  }

  & > title {
    font-family: 'Open Sans', sans-serif;
    border-top-right-radius: 14px;
    display: block;
    padding: 3px 5px;
    background-color: var(--site-strong-color);
    color: #fafafa;
  }

  @media print {
    margin-bottom: 0.75rem;
  }
}

a.more {
  color: var(--site-link-color);
  font-style: italic;
}


.printOnly {
  display: none;
}

@media print {
  :root {
    --site-bg-color: white;
    --site-fg-color: #1c1c1c;
  }

  .panel {
    margin-top: 0.25rem;
    --site-bg-color: #9a67cc;
  }

  se-header,
  #pageFooter * {
    color: #2e0e85;

    a {
      color: #3f0fc4;
      margin-left: 0.25rem;
    }
  }

  @page {
    size: a4;
    margin:2rem;
    padding:0
  }

  body {
    margin: 0;
    padding: 0;
    background-color: white;
  }

  section.panel {
    break-inside: avoid;
  }
  #pageFooter {
    & * {
      display: none;
    }
  }
  #pageFooter .printOnly,
  #pageFooter .printOnly *,
  .printOnly *,
  .printOnly {
    display: inherit;

    & a {
      display: inline-block;
    }
  }

  section > main {
    padding:1rem;
  }

  a.more {
    display: none;
    color: #00000000;
    height: 0;
    padding: 0;
    margin: 0;
  }
  /* a.more::after {
    display: block;
    color: black;
    text-align: center;
    content: 'For more information visit https://sanderelias.nl/cv';
  } */
}
